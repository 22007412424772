<template>
    <div id="nav"
        :class="{ 'light-header': headerLight, 'blue-header': headerBlue }">
        <div class="container padding-heder">
            <TopHeader :whatsapp_url="whatsapp_url" :twitter_url="twitter_url" :facebook_url="facebook_url"
                :linkedIn_url="linkedIn_url" :youtube_url="youtube_url" :instagram_url="instagram_url"
                :telegram_url="telegram_url" :snapchat_url="snapchat_url"  />
            <BottomHeader />
        </div>
    </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
export default {
    components: {
        TopHeader: defineAsyncComponent(() => import('@/components/Common/Client/TopHeader.vue')),
        BottomHeader: defineAsyncComponent(() => import('@/components/Common/Client/BottomHeader.vue')),
    },
    computed: {
        headerLight() {
            if ( this.$route.path.includes('error') || this.$route.path.includes('profile')) {
                return true
            }
            else {
                return false
            }
        },
        headerBlue() {
            if (  this.$route.path.includes('faqs') || this.$route.path.includes('reset-password') 
            || this.$route.path.includes('privacy-policy') || this.$route.path.includes('using-policy')
            || this.$route.path.includes('details') || this.$route.path.includes('bank-accounts')
            || this.$route.path.includes('jobs') || this.$route.path.includes('downloads-center')
            || this.$route.path.includes('order') || this.$route.path.includes('login') 
            || this.$route.path.includes('register')  || this.$route.path.includes('forgot') 
            || this.$route.path.includes('/commercial-franchise') || this.$route.path.includes('/thanks') 
            || this.$route.path.includes('/verification')
             
        ) {
                return true
            }
            else {
                return false
            }
        },
        headerTopShow() {
            if (this.$route.path == '/') {
                return true
            }
            else {
                return false
            }
        }
    },
    mounted() {
        // $(window).scroll(function () {
        //     var scroll = $(window).scrollTop();
        //     if (scroll > 0) {
        //         $('#nav').addClass("stiky");
        //         // $('.top-header').addClass("d-none")
        //     } else {
        //         $('#nav').removeClass("stiky");
        //         // $('.top-header').removeClass("d-none")

        //     }
        // });

    },

}
</script>
<style lang="scss"></style>
